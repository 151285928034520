import { RestUrl } from "../common/cyan.types";

export class UserUrl {
  public static getUserById: (userId: bigint) => RestUrl = userId => ["GET", `/users/${userId}`];
  public static getMyInfo: RestUrl = ["GET", "/user/my"];
  public static patchMyInfo: RestUrl = ["PATCH", "/user/my"];
  public static getUsers: RestUrl = ["GET", "/users"];
  public static paginateUsers: RestUrl = ["GET", "/users/paginate"];
  public static createUser: RestUrl = ["POST", "/users"];
  public static checkIsPasswordConfigured: RestUrl = ["POST", "/user/password/configured"];
  public static resetUserPassword: RestUrl = ["POST", "/user/password-reset"];
}
