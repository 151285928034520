import { Suspense, useEffect } from "react";
import { useStore } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { setDefaultTransformResponse } from "@coralblack/flax";
import { loadTheme } from "@fluentui/react";
import { SSO } from "@pages/auth/SSO";
import { ErrorLayout } from "@pages/system/layout/Error";
import { RootState } from "maven-lib/dist/states";
import { classNames } from "maven-lib/dist/utils/misc";
import { MyInfoGuard } from "./MyInfoGuard";
import { dialogIfTokenDestroyedByNewToken } from "@maven-surface/components/common/auth/TokenInvalidDialog";
import { MsaAuthRoute } from "@maven-surface/components/common/MsaAuthRoute";

import "@maven-surface/assets/style/App.scss";
import "@coralblack/flax/dist/styles/index.css";

loadTheme({
  defaultFontStyle: { fontFamily: '"Nunito", "Noto Sans KR", sans-serif', fontWeight: "400" },
  fonts: {
    xSmall: {
      fontSize: "0.69rem",
    },
    small: {
      fontSize: "0.75rem",
    },
    medium: {
      fontSize: "0.82rem",
    },
    large: {
      fontSize: "0.91rem",
    },
    xLarge: {
      fontSize: "1rem",
    },
  },
});

function RedirectToDomain({ domain }: { domain?: string }) {
  useEffect(() => {
    document.location.href = `${domain || ""}${document.location.pathname}${document.location.search}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
}

function App() {
  const session = useStore<RootState>().getState().session;
  const appBadge = process.env.REACT_APP_BADGE;
  const appBadgeDisabled = process.env.REACT_APP_BADGE_DISABLE;

  useEffect(() => {
    document.body.classList.add("ready");

    setTimeout(() => {
      document.getElementById("spinner")?.remove();
    }, 1500);
  });

  setDefaultTransformResponse([
    function (data) {
      const o = JSON.parse(data);

      dialogIfTokenDestroyedByNewToken(o);

      return o;
    },
  ]);

  const demo = ((v) => (["local", "develop", "demo"].includes(String(process.env.REACT_APP_STAGE)) && v?.length < 30 ? v : null))(
    String(String(window.location.search || "").split("?")[1] || "")
      .split("&")
      .filter((x) => x.startsWith("demo-msg="))
      .map((x) => x.split("=")[1])[0]
  );

  return (
    <BrowserRouter>
      <div
        className={classNames(
          "app-wrapper",
          !!appBadgeDisabled && `badge--disabled badge--${appBadgeDisabled}`,
          `badge--${String(appBadge || "unknown").toLowerCase()}`,
          !!demo && "--demo"
        )}
      >
        <div className="app-demo-message">{demo}</div>
        {!appBadgeDisabled && appBadge && (
          <div className={classNames("notranslate app-sidebar-ribbon", `--${String(appBadge).toLowerCase()}`)}>{appBadge}</div>
        )}
        <Suspense
          fallback={
            <div className="suspense-loading">
              <div className="loading">
                <div className="loader" />
              </div>
            </div>
          }
        >
          <Switch>
            <Route exact={true} path="/404" component={ErrorLayout} />
            <Route exact={true} path="/auth" render={() => <SSO session={session} />} />
            <MsaAuthRoute
              session={session}
              authStrategy="AUTHORIZED_ONLY"
              path="/"
              denyRedirectTo="/auth"
              render={() => <MyInfoGuard component={() => <RedirectToDomain domain={process.env.REACT_APP_AUTH_FE_URL} />} />}
            />
            <Redirect from="*" to="/404?ep" />
          </Switch>
        </Suspense>
      </div>
    </BrowserRouter>
  );
}

export default App;
