export declare type LaString = LoString | string;

export interface LoString {
  en: string;
  ko?: string;
  ja?: string;
  zh?: string;
}

export enum LoLang {
  EN = "EN",
  KO = "KO",
  JA = "JA",
  ZH = "ZH",
}

export function readableLoLang(loLang: LoLang): string {
  switch (loLang) {
    case LoLang.EN:
      return "English";
    case LoLang.JA:
      return "日本語";
    case LoLang.KO:
      return "한국어";
    case LoLang.ZH:
      return "中文";
    default:
      return "Unknown";
  }
}

export enum MavenService {
  MavenClinicalCloud = "MAVEN-CLINICAL-CLOUD",
  MavenSSO = "MAVEN-SSO",
  MavenPortal = "MAVEN-PORTAL",
  MavenAuth = "MAVEN-AUTH",
  //
  MavenCDMS = "MAVEN-CDMS",
  MavenBuilder = "MAVEN-BUILDER",
  MavenECoa = "MAVEN-ECOA",
  MavenDocs = "MAVEN-DOCS",
  MavenSafety = "MAVEN-SAFETY",
  MavenValidator = "MAVEN-VALIDATOR",
  MavenEConsent = "MAVEN-ECONSENT",
  //
  MavenSign = "MAVEN-SIGN",
  MavenTMF = "MAVEN-TMF",
  MavenOCR = "MAVEN-OCR",
  //
  MavenAdmin = "MAVEN-ADMIN",
}

export function hasOwnProperty<X extends {}, Y extends PropertyKey>(obj: X, prop: Y): obj is X & Record<Y, unknown> {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}

export type UnionRight<T, R> = Omit<T, keyof R> & R;

type Cons<H, T> = T extends readonly any[] ? (((h: H, ...t: T) => void) extends (...r: infer R) => void ? R : never) : never;
type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, ...0[]];
export type Paths<T, D extends number = 5> = [D] extends [never]
  ? never
  : T extends object
  ? {
      [K in keyof T]-?: [K] | (Paths<T[K], Prev[D]> extends infer P ? (P extends [] ? never : Cons<K, P>) : never);
    }[keyof T]
  : [];

export type ResourceThrowable = boolean | 400 | 401 | 403 | 404 | 409;

export enum TimezoneOffset {
  Africa_Abidjan = "Africa/Abidjan (UTC +00:00)",
  Africa_Algiers = "Africa/Algiers (UTC +01:00)",
  Africa_Bissau = "Africa/Bissau (UTC +00:00)",
  Africa_Cairo = "Africa/Cairo (UTC +02:00)",
  Africa_Casablanca = "Africa/Casablanca (UTC +01:00)",
  Africa_Ceuta = "Africa/Ceuta (UTC +01:00)",
  Africa_El_Aaiun = "Africa/El_Aaiun (UTC +01:00)",
  Africa_Johannesburg = "Africa/Johannesburg (UTC +02:00)",
  Africa_Juba = "Africa/Juba (UTC +02:00)",
  Africa_Khartoum = "Africa/Khartoum (UTC +02:00)",
  Africa_Lagos = "Africa/Lagos (UTC +01:00)",
  Africa_Maputo = "Africa/Maputo (UTC +02:00)",
  Africa_Monrovia = "Africa/Monrovia (UTC +00:00)",
  Africa_Nairobi = "Africa/Nairobi (UTC +03:00)",
  Africa_Ndjamena = "Africa/Ndjamena (UTC +01:00)",
  Africa_Sao_Tome = "Africa/Sao_Tome (UTC +00:00)",
  Africa_Tripoli = "Africa/Tripoli (UTC +02:00)",
  Africa_Tunis = "Africa/Tunis (UTC +01:00)",
  Africa_Windhoek = "Africa/Windhoek (UTC +02:00)",
  America_Adak = "America/Adak (UTC -10:00)",
  America_Anchorage = "America/Anchorage (UTC -09:00)",
  America_Araguaina = "America/Araguaina (UTC -03:00)",
  America_Argentina_Buenos_Aires = "America/Argentina/Buenos_Aires (UTC -03:00)",
  America_Argentina_Catamarca = "America/Argentina/Catamarca (UTC -03:00)",
  America_Argentina_Cordoba = "America/Argentina/Cordoba (UTC -03:00)",
  America_Argentina_Jujuy = "America/Argentina/Jujuy (UTC -03:00)",
  America_Argentina_La_Rioja = "America/Argentina/La_Rioja (UTC -03:00)",
  America_Argentina_Mendoza = "America/Argentina/Mendoza (UTC -03:00)",
  America_Argentina_Rio_Gallegos = "America/Argentina/Rio_Gallegos (UTC -03:00)",
  America_Argentina_Salta = "America/Argentina/Salta (UTC -03:00)",
  America_Argentina_San_Juan = "America/Argentina/San_Juan (UTC -03:00)",
  America_Argentina_San_Luis = "America/Argentina/San_Luis (UTC -03:00)",
  America_Argentina_Tucuman = "America/Argentina/Tucuman (UTC -03:00)",
  America_Argentina_Ushuaia = "America/Argentina/Ushuaia (UTC -03:00)",
  America_Asuncion = "America/Asuncion (UTC -04:00)",
  America_Bahia = "America/Bahia (UTC -03:00)",
  America_Bahia_Banderas = "America/Bahia_Banderas (UTC -06:00)",
  America_Barbados = "America/Barbados (UTC -04:00)",
  America_Belem = "America/Belem (UTC -03:00)",
  America_Belize = "America/Belize (UTC -06:00)",
  America_Boa_Vista = "America/Boa_Vista (UTC -04:00)",
  America_Bogota = "America/Bogota (UTC -05:00)",
  America_Boise = "America/Boise (UTC -07:00)",
  America_Cambridge_Bay = "America/Cambridge_Bay (UTC -07:00)",
  America_Campo_Grande = "America/Campo_Grande (UTC -04:00)",
  America_Cancun = "America/Cancun (UTC -05:00)",
  America_Caracas = "America/Caracas (UTC -04:00)",
  America_Cayenne = "America/Cayenne (UTC -03:00)",
  America_Chicago = "America/Chicago (UTC -06:00)",
  America_Chihuahua = "America/Chihuahua (UTC -07:00)",
  America_Costa_Rica = "America/Costa_Rica (UTC -06:00)",
  America_Cuiaba = "America/Cuiaba (UTC -04:00)",
  America_Danmarkshavn = "America/Danmarkshavn (UTC +00:00)",
  America_Dawson = "America/Dawson (UTC -07:00)",
  America_Dawson_Creek = "America/Dawson_Creek (UTC -07:00)",
  America_Denver = "America/Denver (UTC -07:00)",
  America_Detroit = "America/Detroit (UTC -05:00)",
  America_Edmonton = "America/Edmonton (UTC -07:00)",
  America_Eirunepe = "America/Eirunepe (UTC -05:00)",
  America_El_Salvador = "America/El_Salvador (UTC -06:00)",
  America_Fort_Nelson = "America/Fort_Nelson (UTC -07:00)",
  America_Fortaleza = "America/Fortaleza (UTC -03:00)",
  America_Glace_Bay = "America/Glace_Bay (UTC -04:00)",
  America_Goose_Bay = "America/Goose_Bay (UTC -04:00)",
  America_Grand_Turk = "America/Grand_Turk (UTC -05:00)",
  America_Guatemala = "America/Guatemala (UTC -06:00)",
  America_Guayaquil = "America/Guayaquil (UTC -05:00)",
  America_Guyana = "America/Guyana (UTC -04:00)",
  America_Halifax = "America/Halifax (UTC -04:00)",
  America_Havana = "America/Havana (UTC -05:00)",
  America_Hermosillo = "America/Hermosillo (UTC -07:00)",
  America_Indiana_Indianapolis = "America/Indiana/Indianapolis (UTC -05:00)",
  America_Indiana_Knox = "America/Indiana/Knox (UTC -06:00)",
  America_Indiana_Marengo = "America/Indiana/Marengo (UTC -05:00)",
  America_Indiana_Petersburg = "America/Indiana/Petersburg (UTC -05:00)",
  America_Indiana_Tell_City = "America/Indiana/Tell_City (UTC -06:00)",
  America_Indiana_Vevay = "America/Indiana/Vevay (UTC -05:00)",
  America_Indiana_Vincennes = "America/Indiana/Vincennes (UTC -05:00)",
  America_Indiana_Winamac = "America/Indiana/Winamac (UTC -05:00)",
  America_Inuvik = "America/Inuvik (UTC -07:00)",
  America_Iqaluit = "America/Iqaluit (UTC -05:00)",
  America_Jamaica = "America/Jamaica (UTC -05:00)",
  America_Juneau = "America/Juneau (UTC -09:00)",
  America_Kentucky_Louisville = "America/Kentucky/Louisville (UTC -05:00)",
  America_Kentucky_Monticello = "America/Kentucky/Monticello (UTC -05:00)",
  America_La_Paz = "America/La_Paz (UTC -04:00)",
  America_Lima = "America/Lima (UTC -05:00)",
  America_Los_Angeles = "America/Los_Angeles (UTC -08:00)",
  America_Maceio = "America/Maceio (UTC -03:00)",
  America_Managua = "America/Managua (UTC -06:00)",
  America_Manaus = "America/Manaus (UTC -04:00)",
  America_Martinique = "America/Martinique (UTC -04:00)",
  America_Matamoros = "America/Matamoros (UTC -06:00)",
  America_Mazatlan = "America/Mazatlan (UTC -07:00)",
  America_Menominee = "America/Menominee (UTC -06:00)",
  America_Merida = "America/Merida (UTC -06:00)",
  America_Metlakatla = "America/Metlakatla (UTC -09:00)",
  America_Mexico_City = "America/Mexico_City (UTC -06:00)",
  America_Miquelon = "America/Miquelon (UTC -03:00)",
  America_Moncton = "America/Moncton (UTC -04:00)",
  America_Monterrey = "America/Monterrey (UTC -06:00)",
  America_Montevideo = "America/Montevideo (UTC -03:00)",
  America_New_York = "America/New_York (UTC -05:00)",
  America_Nipigon = "America/Nipigon (UTC -05:00)",
  America_Nome = "America/Nome (UTC -09:00)",
  America_Noronha = "America/Noronha (UTC -02:00)",
  America_North_Dakota_Beulah = "America/North_Dakota/Beulah (UTC -06:00)",
  America_North_Dakota_Center = "America/North_Dakota/Center (UTC -06:00)",
  America_North_Dakota_New_Salem = "America/North_Dakota/New_Salem (UTC -06:00)",
  America_Nuuk = "America/Nuuk (UTC -03:00)",
  America_Ojinaga = "America/Ojinaga (UTC -07:00)",
  America_Panama = "America/Panama (UTC -05:00)",
  America_Pangnirtung = "America/Pangnirtung (UTC -05:00)",
  America_Paramaribo = "America/Paramaribo (UTC -03:00)",
  America_Phoenix = "America/Phoenix (UTC -07:00)",
  America_Port_Au_Prince = "America/Port-au-Prince (UTC -05:00)",
  America_Porto_Velho = "America/Porto_Velho (UTC -04:00)",
  America_Puerto_Rico = "America/Puerto_Rico (UTC -04:00)",
  America_Punta_Arenas = "America/Punta_Arenas (UTC -03:00)",
  America_Rainy_River = "America/Rainy_River (UTC -06:00)",
  America_Rankin_Inlet = "America/Rankin_Inlet (UTC -06:00)",
  America_Recife = "America/Recife (UTC -03:00)",
  America_Regina = "America/Regina (UTC -06:00)",
  America_Resolute = "America/Resolute (UTC -06:00)",
  America_Rio_Branco = "America/Rio_Branco (UTC -05:00)",
  America_Santarem = "America/Santarem (UTC -03:00)",
  America_Santiago = "America/Santiago (UTC -04:00)",
  America_Santo_Domingo = "America/Santo_Domingo (UTC -04:00)",
  America_Sao_Paulo = "America/Sao_Paulo (UTC -03:00)",
  America_Scoresbysund = "America/Scoresbysund (UTC -01:00)",
  America_Sitka = "America/Sitka (UTC -09:00)",
  America_St_Johns = "America/St_Johns (UTC -03:30)",
  America_Swift_Current = "America/Swift_Current (UTC -06:00)",
  America_Tegucigalpa = "America/Tegucigalpa (UTC -06:00)",
  America_Thule = "America/Thule (UTC -04:00)",
  America_Thunder_Bay = "America/Thunder_Bay (UTC -05:00)",
  America_Tijuana = "America/Tijuana (UTC -08:00)",
  America_Toronto = "America/Toronto (UTC -05:00)",
  America_Vancouver = "America/Vancouver (UTC -08:00)",
  America_Whitehorse = "America/Whitehorse (UTC -07:00)",
  America_Winnipeg = "America/Winnipeg (UTC -06:00)",
  America_Yakutat = "America/Yakutat (UTC -09:00)",
  America_Yellowknife = "America/Yellowknife (UTC -07:00)",
  Antarctica_Casey = "Antarctica/Casey (UTC +11:00)",
  Antarctica_Davis = "Antarctica/Davis (UTC +07:00)",
  Antarctica_Macquarie = "Antarctica/Macquarie (UTC +10:00)",
  Antarctica_Mawson = "Antarctica/Mawson (UTC +05:00)",
  Antarctica_Palmer = "Antarctica/Palmer (UTC -03:00)",
  Antarctica_Rothera = "Antarctica/Rothera (UTC -03:00)",
  Antarctica_Troll = "Antarctica/Troll (UTC +00:00)",
  Antarctica_Vostok = "Antarctica/Vostok (UTC +06:00)",
  Asia_Almaty = "Asia/Almaty (UTC +06:00)",
  Asia_Amman = "Asia/Amman (UTC +02:00)",
  Asia_Anadyr = "Asia/Anadyr (UTC +12:00)",
  Asia_Aqtau = "Asia/Aqtau (UTC +05:00)",
  Asia_Aqtobe = "Asia/Aqtobe (UTC +05:00)",
  Asia_Ashgabat = "Asia/Ashgabat (UTC +05:00)",
  Asia_Atyrau = "Asia/Atyrau (UTC +05:00)",
  Asia_Baghdad = "Asia/Baghdad (UTC +03:00)",
  Asia_Baku = "Asia/Baku (UTC +04:00)",
  Asia_Bangkok = "Asia/Bangkok (UTC +07:00)",
  Asia_Barnaul = "Asia/Barnaul (UTC +07:00)",
  Asia_Beirut = "Asia/Beirut (UTC +02:00)",
  Asia_Bishkek = "Asia/Bishkek (UTC +06:00)",
  Asia_Brunei = "Asia/Brunei (UTC +08:00)",
  Asia_Chita = "Asia/Chita (UTC +09:00)",
  Asia_Choibalsan = "Asia/Choibalsan (UTC +08:00)",
  Asia_Colombo = "Asia/Colombo (UTC +05:30)",
  Asia_Damascus = "Asia/Damascus (UTC +02:00)",
  Asia_Dhaka = "Asia/Dhaka (UTC +06:00)",
  Asia_Dili = "Asia/Dili (UTC +09:00)",
  Asia_Dubai = "Asia/Dubai (UTC +04:00)",
  Asia_Dushanbe = "Asia/Dushanbe (UTC +05:00)",
  Asia_Famagusta = "Asia/Famagusta (UTC +02:00)",
  Asia_Gaza = "Asia/Gaza (UTC +02:00)",
  Asia_Hebron = "Asia/Hebron (UTC +02:00)",
  Asia_Ho_Chi_Minh = "Asia/Ho_Chi_Minh (UTC +07:00)",
  Asia_Hong_Kong = "Asia/Hong_Kong (UTC +08:00)",
  Asia_Hovd = "Asia/Hovd (UTC +07:00)",
  Asia_Irkutsk = "Asia/Irkutsk (UTC +08:00)",
  Asia_Jakarta = "Asia/Jakarta (UTC +07:00)",
  Asia_Jayapura = "Asia/Jayapura (UTC +09:00)",
  Asia_Jerusalem = "Asia/Jerusalem (UTC +02:00)",
  Asia_Kabul = "Asia/Kabul (UTC +04:30)",
  Asia_Kamchatka = "Asia/Kamchatka (UTC +12:00)",
  Asia_Karachi = "Asia/Karachi (UTC +05:00)",
  Asia_Kathmandu = "Asia/Kathmandu (UTC +05:45)",
  Asia_Khandyga = "Asia/Khandyga (UTC +09:00)",
  Asia_Kolkata = "Asia/Kolkata (UTC +05:30)",
  Asia_Krasnoyarsk = "Asia/Krasnoyarsk (UTC +07:00)",
  Asia_Kuala_Lumpur = "Asia/Kuala_Lumpur (UTC +08:00)",
  Asia_Kuching = "Asia/Kuching (UTC +08:00)",
  Asia_Macau = "Asia/Macau (UTC +08:00)",
  Asia_Magadan = "Asia/Magadan (UTC +11:00)",
  Asia_Makassar = "Asia/Makassar (UTC +08:00)",
  Asia_Manila = "Asia/Manila (UTC +08:00)",
  Asia_Nicosia = "Asia/Nicosia (UTC +02:00)",
  Asia_Novokuznetsk = "Asia/Novokuznetsk (UTC +07:00)",
  Asia_Novosibirsk = "Asia/Novosibirsk (UTC +07:00)",
  Asia_Omsk = "Asia/Omsk (UTC +06:00)",
  Asia_Oral = "Asia/Oral (UTC +05:00)",
  Asia_Pontianak = "Asia/Pontianak (UTC +07:00)",
  Asia_Pyongyang = "Asia/Pyongyang (UTC +09:00)",
  Asia_Qatar = "Asia/Qatar (UTC +03:00)",
  Asia_Qostanay = "Asia/Qostanay (UTC +06:00)",
  Asia_Qyzylorda = "Asia/Qyzylorda (UTC +05:00)",
  Asia_Riyadh = "Asia/Riyadh (UTC +03:00)",
  Asia_Sakhalin = "Asia/Sakhalin (UTC +11:00)",
  Asia_Samarkand = "Asia/Samarkand (UTC +05:00)",
  Asia_Seoul = "Asia/Seoul (UTC +09:00)",
  Asia_Shanghai = "Asia/Shanghai (UTC +08:00)",
  Asia_Singapore = "Asia/Singapore (UTC +08:00)",
  Asia_Srednekolymsk = "Asia/Srednekolymsk (UTC +11:00)",
  Asia_Taipei = "Asia/Taipei (UTC +08:00)",
  Asia_Tashkent = "Asia/Tashkent (UTC +05:00)",
  Asia_Tbilisi = "Asia/Tbilisi (UTC +04:00)",
  Asia_Tehran = "Asia/Tehran (UTC +03:30)",
  Asia_Thimphu = "Asia/Thimphu (UTC +06:00)",
  Asia_Tokyo = "Asia/Tokyo (UTC +09:00)",
  Asia_Tomsk = "Asia/Tomsk (UTC +07:00)",
  Asia_Ulaanbaatar = "Asia/Ulaanbaatar (UTC +08:00)",
  Asia_Urumqi = "Asia/Urumqi (UTC +06:00)",
  Asia_Ust_Nera = "Asia/Ust-Nera (UTC +10:00)",
  Asia_Vladivostok = "Asia/Vladivostok (UTC +10:00)",
  Asia_Yakutsk = "Asia/Yakutsk (UTC +09:00)",
  Asia_Yangon = "Asia/Yangon (UTC +06:30)",
  Asia_Yekaterinburg = "Asia/Yekaterinburg (UTC +05:00)",
  Asia_Yerevan = "Asia/Yerevan (UTC +04:00)",
  Atlantic_Azores = "Atlantic/Azores (UTC -01:00)",
  Atlantic_Bermuda = "Atlantic/Bermuda (UTC -04:00)",
  Atlantic_Canary = "Atlantic/Canary (UTC +00:00)",
  Atlantic_Cape_Verde = "Atlantic/Cape_Verde (UTC -01:00)",
  Atlantic_Faroe = "Atlantic/Faroe (UTC +00:00)",
  Atlantic_Madeira = "Atlantic/Madeira (UTC +00:00)",
  Atlantic_Reykjavik = "Atlantic/Reykjavik (UTC +00:00)",
  Atlantic_South_Georgia = "Atlantic/South_Georgia (UTC -02:00)",
  Atlantic_Stanley = "Atlantic/Stanley (UTC -03:00)",
  Australia_Adelaide = "Australia/Adelaide (UTC +09:30)",
  Australia_Brisbane = "Australia/Brisbane (UTC +10:00)",
  Australia_Broken_Hill = "Australia/Broken_Hill (UTC +09:30)",
  Australia_Darwin = "Australia/Darwin (UTC +09:30)",
  Australia_Eucla = "Australia/Eucla (UTC +08:45)",
  Australia_Hobart = "Australia/Hobart (UTC +10:00)",
  Australia_Lindeman = "Australia/Lindeman (UTC +10:00)",
  Australia_Lord_Howe = "Australia/Lord_Howe (UTC +10:30)",
  Australia_Melbourne = "Australia/Melbourne (UTC +10:00)",
  Australia_Perth = "Australia/Perth (UTC +08:00)",
  Australia_Sydney = "Australia/Sydney (UTC +10:00)",
  CET = "CET (UTC +01:00)",
  CST6CDT = "CST6CDT (UTC -06:00)",
  EET = "EET (UTC +02:00)",
  EST = "EST (UTC -05:00)",
  EST5EDT = "EST5EDT (UTC -05:00)",
  Etc_GMT = "Etc/GMT (UTC +00:00)",
  Etc_GMTP1 = "Etc/GMT+1 (UTC -01:00)",
  Etc_GMTP10 = "Etc/GMT+10 (UTC -10:00)",
  Etc_GMTP11 = "Etc/GMT+11 (UTC -11:00)",
  Etc_GMTP12 = "Etc/GMT+12 (UTC -12:00)",
  Etc_GMTP2 = "Etc/GMT+2 (UTC -02:00)",
  Etc_GMTP3 = "Etc/GMT+3 (UTC -03:00)",
  Etc_GMTP4 = "Etc/GMT+4 (UTC -04:00)",
  Etc_GMTP5 = "Etc/GMT+5 (UTC -05:00)",
  Etc_GMTP6 = "Etc/GMT+6 (UTC -06:00)",
  Etc_GMTP7 = "Etc/GMT+7 (UTC -07:00)",
  Etc_GMTP8 = "Etc/GMT+8 (UTC -08:00)",
  Etc_GMTP9 = "Etc/GMT+9 (UTC -09:00)",
  Etc_GMTM1 = "Etc/GMT-1 (UTC +01:00)",
  Etc_GMTM10 = "Etc/GMT-10 (UTC +10:00)",
  Etc_GMTM11 = "Etc/GMT-11 (UTC +11:00)",
  Etc_GMTM12 = "Etc/GMT-12 (UTC +12:00)",
  Etc_GMTM13 = "Etc/GMT-13 (UTC +13:00)",
  Etc_GMTM14 = "Etc/GMT-14 (UTC +14:00)",
  Etc_GMTM2 = "Etc/GMT-2 (UTC +02:00)",
  Etc_GMTM3 = "Etc/GMT-3 (UTC +03:00)",
  Etc_GMTM4 = "Etc/GMT-4 (UTC +04:00)",
  Etc_GMTM5 = "Etc/GMT-5 (UTC +05:00)",
  Etc_GMTM6 = "Etc/GMT-6 (UTC +06:00)",
  Etc_GMTM7 = "Etc/GMT-7 (UTC +07:00)",
  Etc_GMTM8 = "Etc/GMT-8 (UTC +08:00)",
  Etc_GMTM9 = "Etc/GMT-9 (UTC +09:00)",
  Etc_UTC = "Etc/UTC (UTC +00:00)",
  Europe_Amsterdam = "Europe/Amsterdam (UTC +01:00)",
  Europe_Andorra = "Europe/Andorra (UTC +01:00)",
  Europe_Astrakhan = "Europe/Astrakhan (UTC +04:00)",
  Europe_Athens = "Europe/Athens (UTC +02:00)",
  Europe_Belgrade = "Europe/Belgrade (UTC +01:00)",
  Europe_Berlin = "Europe/Berlin (UTC +01:00)",
  Europe_Brussels = "Europe/Brussels (UTC +01:00)",
  Europe_Bucharest = "Europe/Bucharest (UTC +02:00)",
  Europe_Budapest = "Europe/Budapest (UTC +01:00)",
  Europe_Chisinau = "Europe/Chisinau (UTC +02:00)",
  Europe_Copenhagen = "Europe/Copenhagen (UTC +01:00)",
  Europe_Dublin = "Europe/Dublin (UTC +01:00)",
  Europe_Gibraltar = "Europe/Gibraltar (UTC +01:00)",
  Europe_Helsinki = "Europe/Helsinki (UTC +02:00)",
  Europe_Istanbul = "Europe/Istanbul (UTC +03:00)",
  Europe_Kaliningrad = "Europe/Kaliningrad (UTC +02:00)",
  Europe_Kiev = "Europe/Kiev (UTC +02:00)",
  Europe_Kirov = "Europe/Kirov (UTC +03:00)",
  Europe_Lisbon = "Europe/Lisbon (UTC +00:00)",
  Europe_London = "Europe/London (UTC +00:00)",
  Europe_Luxembourg = "Europe/Luxembourg (UTC +01:00)",
  Europe_Madrid = "Europe/Madrid (UTC +01:00)",
  Europe_Malta = "Europe/Malta (UTC +01:00)",
  Europe_Minsk = "Europe/Minsk (UTC +03:00)",
  Europe_Monaco = "Europe/Monaco (UTC +01:00)",
  Europe_Moscow = "Europe/Moscow (UTC +03:00)",
  Europe_Oslo = "Europe/Oslo (UTC +01:00)",
  Europe_Paris = "Europe/Paris (UTC +01:00)",
  Europe_Prague = "Europe/Prague (UTC +01:00)",
  Europe_Riga = "Europe/Riga (UTC +02:00)",
  Europe_Rome = "Europe/Rome (UTC +01:00)",
  Europe_Samara = "Europe/Samara (UTC +04:00)",
  Europe_Saratov = "Europe/Saratov (UTC +04:00)",
  Europe_Simferopol = "Europe/Simferopol (UTC +03:00)",
  Europe_Sofia = "Europe/Sofia (UTC +02:00)",
  Europe_Stockholm = "Europe/Stockholm (UTC +01:00)",
  Europe_Tallinn = "Europe/Tallinn (UTC +02:00)",
  Europe_Tirane = "Europe/Tirane (UTC +01:00)",
  Europe_Ulyanovsk = "Europe/Ulyanovsk (UTC +04:00)",
  Europe_Uzhgorod = "Europe/Uzhgorod (UTC +02:00)",
  Europe_Vienna = "Europe/Vienna (UTC +01:00)",
  Europe_Vilnius = "Europe/Vilnius (UTC +02:00)",
  Europe_Volgograd = "Europe/Volgograd (UTC +03:00)",
  Europe_Warsaw = "Europe/Warsaw (UTC +01:00)",
  Europe_Zaporozhye = "Europe/Zaporozhye (UTC +02:00)",
  Europe_Zurich = "Europe/Zurich (UTC +01:00)",
  Factory = "Factory (UTC +00:00)",
  HST = "HST (UTC -10:00)",
  Indian_Chagos = "Indian/Chagos (UTC +06:00)",
  Indian_Christmas = "Indian/Christmas (UTC +07:00)",
  Indian_Cocos = "Indian/Cocos (UTC +06:30)",
  Indian_Kerguelen = "Indian/Kerguelen (UTC +05:00)",
  Indian_Mahe = "Indian/Mahe (UTC +04:00)",
  Indian_Maldives = "Indian/Maldives (UTC +05:00)",
  Indian_Mauritius = "Indian/Mauritius (UTC +04:00)",
  Indian_Reunion = "Indian/Reunion (UTC +04:00)",
  MET = "MET (UTC +01:00)",
  MST = "MST (UTC -07:00)",
  MST7MDT = "MST7MDT (UTC -07:00)",
  PST8PDT = "PST8PDT (UTC -08:00)",
  Pacific_Apia = "Pacific/Apia (UTC +13:00)",
  Pacific_Auckland = "Pacific/Auckland (UTC +12:00)",
  Pacific_Bougainville = "Pacific/Bougainville (UTC +11:00)",
  Pacific_Chatham = "Pacific/Chatham (UTC +12:45)",
  Pacific_Chuuk = "Pacific/Chuuk (UTC +10:00)",
  Pacific_Easter = "Pacific/Easter (UTC -06:00)",
  Pacific_Efate = "Pacific/Efate (UTC +11:00)",
  Pacific_Fakaofo = "Pacific/Fakaofo (UTC +13:00)",
  Pacific_Fiji = "Pacific/Fiji (UTC +12:00)",
  Pacific_Funafuti = "Pacific/Funafuti (UTC +12:00)",
  Pacific_Galapagos = "Pacific/Galapagos (UTC -06:00)",
  Pacific_Gambier = "Pacific/Gambier (UTC -09:00)",
  Pacific_Guadalcanal = "Pacific/Guadalcanal (UTC +11:00)",
  Pacific_Guam = "Pacific/Guam (UTC +10:00)",
  Pacific_Honolulu = "Pacific/Honolulu (UTC -10:00)",
  Pacific_Kanton = "Pacific/Kanton (UTC +13:00)",
  Pacific_Kiritimati = "Pacific/Kiritimati (UTC +14:00)",
  Pacific_Kosrae = "Pacific/Kosrae (UTC +11:00)",
  Pacific_Kwajalein = "Pacific/Kwajalein (UTC +12:00)",
  Pacific_Majuro = "Pacific/Majuro (UTC +12:00)",
  Pacific_Marquesas = "Pacific/Marquesas (UTC -09:30)",
  Pacific_Nauru = "Pacific/Nauru (UTC +12:00)",
  Pacific_Niue = "Pacific/Niue (UTC -11:00)",
  Pacific_Norfolk = "Pacific/Norfolk (UTC +11:00)",
  Pacific_Noumea = "Pacific/Noumea (UTC +11:00)",
  Pacific_Pago_Pago = "Pacific/Pago_Pago (UTC -11:00)",
  Pacific_Palau = "Pacific/Palau (UTC +09:00)",
  Pacific_Pitcairn = "Pacific/Pitcairn (UTC -08:00)",
  Pacific_Pohnpei = "Pacific/Pohnpei (UTC +11:00)",
  Pacific_Port_Moresby = "Pacific/Port_Moresby (UTC +10:00)",
  Pacific_Rarotonga = "Pacific/Rarotonga (UTC -10:00)",
  Pacific_Tahiti = "Pacific/Tahiti (UTC -10:00)",
  Pacific_Tarawa = "Pacific/Tarawa (UTC +12:00)",
  Pacific_Tongatapu = "Pacific/Tongatapu (UTC +13:00)",
  Pacific_Wake = "Pacific/Wake (UTC +12:00)",
  Pacific_Wallis = "Pacific/Wallis (UTC +12:00)",
  WET = "WET (UTC +00:00)",
}
