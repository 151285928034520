import React, { useState } from "react";
import { render } from "react-dom";
import { Provider, useDispatch } from "react-redux";
import { store } from "maven-lib/dist/states";
import { sessionSlice } from "maven-lib/dist/states/reducers/session";
import { CrDialog } from "../../base/CrDialog";

export function dialogIfTokenDestroyedByNewToken(o: { code: string }) {
  if (o?.code === "CREDENTIAL-INVALID-BY-NEW-TOKEN") {
    const RootDialogContainerId = "root-dialog-container";
    const container =
      document.getElementById(RootDialogContainerId) ||
      (() => {
        const dom = document.createElement("div");
        dom.id = RootDialogContainerId;
        dom.classList.add("root-dialog-container");
        document.body.appendChild(dom);

        return dom;
      })();

    const wrapper = document.createElement("div");
    wrapper.classList.add("root-dialog-wrapper");
    container.appendChild(wrapper);

    render(
      <Provider store={store}>
        <TokenInvalidDialog />
      </Provider>,
      wrapper
    );
  }
}

export function TokenInvalidDialog() {
  const dispatch = useDispatch();
  const [dialogVisibility, setDialogVisibility] = useState<boolean>(true);

  return (
    <>
      <CrDialog
        header="Maven Clinical Cloud"
        visibility={dialogVisibility}
        onConfirm={() => {
          setDialogVisibility(false);

          dispatch(sessionSlice.actions.signOut());
        }}
        confirm="Confirm"
      >
        <div>
          Your session has expired due to additional login with your account.
          <br />
          Return to login page.
        </div>
      </CrDialog>
    </>
  );
}
